import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled, { css } from "styled-components";
import Button from "../General/Button.component";

const IndexPageAboutContainer = styled.section`
  ${(props) =>
    !props.noBG &&
    css`
      background: linear-gradient(
        to right,
        #dcdcdc 0%,
        #dcdcdc 50%,
        #fff 50%,
        #fff 100%
      );
    `}

  & .container {
    display: flex;
  }
  @media (max-width: 1100px) {
    background: white;
  }
  @media (max-width: 992px) {
    & .container {
      flex-direction: column;
      & > div:first-of-type {
        order: 2;
      }
    }
  }
`;

const IndexPageAboutLeftContainer = styled.div`
  max-width: 60%;
  width: 100%;
  position: relative;
  @media (max-width: 992px) {
    max-width: 100%;
  }
`;

const IndexPageAboutRightContainer = styled.div`
  max-width: 40%;
  width: 100%;
  padding: 3rem 1rem 3rem 5rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .lead {
    font-size: 3.6rem;
    font-weight: 900;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    position: relative;
    &:after {
      background: ${({ theme }) => theme.darkBlue};
      width: 20%;
      height: 5px;
      position: absolute;
      content: "";
      display: block;
      bottom: 0;
      left: 0;
    }
  }
  ${({ noButton }) => {
    !noButton &&
      css`
        div:last-of-type {
          justify-content: flex-start;
          display: flex;
          width: 100%;
        }
      `;
  }}
  .content {
    color: ${({ theme }) => theme.darkGray};
    text-align: left;
    p {
      margin-bottom: 2rem;
      line-height: 1.8;
    }
  }
  @media (max-width: 1100px) {
    padding-right: 0;
  }
  @media (max-width: 992px) {
    padding: 0;
    max-width: 100%;
    align-items: center;
    margin-bottom: 3rem;
    .content {
      text-align: center;
    }
    .lead {
      font-size: 2.8rem;
      &:after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    div:last-of-type {
      justify-content: center;
    }
  }
`;

const IndexPageAboutFloatingText = styled.div`
  position: absolute;
  bottom: 0%;
  left: 0;
  padding: 4rem;
  max-width: 55%;
  width: 100%;
  background: ${({ theme }) => theme.darkGray};
  color: white;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.15rem;
  & p {
    font-size: 3.6rem;
  }
  & span {
    font-size: 2rem;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid ${({ theme }) => theme.darkGray};
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: 992px) {
    max-width: 100%;
    & p {
      font-size: 3rem;
    }
    & span {
      font-size: 1.4rem;
    }
  }
  @media (max-width: 992px) {
    padding: 2rem;
    & p {
      font-size: 2rem;
    }
    & span {
      font-size: 1.2rem;
    }
  }
`;

const IndexPageAbout = ({ noButton = false, noBG = false }) => {
  return (
    <IndexPageAboutContainer noBG={noBG}>
      <div className="container">
        <IndexPageAboutLeftContainer>
          <StaticImage src="../../images/pic-main-bg-about-us.jpeg" alt="" />
          <IndexPageAboutFloatingText>
            <span>Miért</span>
            <br />
            <p>
              válasszon
              <br />
              minket?
            </p>
          </IndexPageAboutFloatingText>
        </IndexPageAboutLeftContainer>
        <IndexPageAboutRightContainer noButton={noButton}>
          <div className="lead">Múlt és jelen</div>
          <div className="content">
            <p>
              Az építőipar mindig is vonzott, a gyártás kifejezetten érdekelt és
              úgy gondoltam, hogy a minőségi, jó termékekre mindig van kereslet.
              Az elmúlt közel két évtized során nagyon sok tapasztalatot
              szereztem, hiszen minden projekt más és más.
            </p>
            <p>
              Olyan munkákat is elvállaltunk és igényesen kiviteleztünk,
              amelyekre más vállalkozások nemet mondtak. Jelenleg is számos
              olyan projekten dolgozunk, amelyekre kifejezetten büszke vagyok.
            </p>
            <p>
              Kukk Zoltán
              <br />
              Ügyvezető
            </p>
          </div>
          {!noButton && (
            <Button
              callback={() => navigate("/bemutatkozas")}
              styleO={{ bg: "white", shadow: false }}
              large
            >
              Rólunk
            </Button>
          )}
        </IndexPageAboutRightContainer>
      </div>
    </IndexPageAboutContainer>
  );
};

export default IndexPageAbout;
